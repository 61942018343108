.AuthNav {
  display: flex;
  min-height: 50px;
  align-items: center;
  background-color: #f7f7f9;
  padding: 0 20px;

  img {
    margin: 0;
    margin-right: 24px;
    height: 30px;
    mix-blend-mode: multiply;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      text-align: center;
    }

    a {
      color: #9b9b9b;
      margin-right: 20px;
      padding: 5px 0;
    }

    .active {
      color: #0076ff;
    }
  }

  a {
    margin-left: auto;
    color: #4a4a4a;
    text-decoration: none;
    font-size: 14px;
  }
}
