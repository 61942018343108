@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,800");
@import './scss/theme1.module';
@import '@sweetalert2/theme-material-ui/material-ui.scss';

@font-face {
  font-family: "Asap-Regular";
  src: url("/src/css/fonts/Asap-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Asap-Regular";
  src: url("/src/css/fonts/Asap-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Asap-Regular";
  src: url("/src/css/fonts/Asap-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Asap-Regular";
  src: url("/src/css/fonts/Asap-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-smooth: auto;
  background-color: #f8f8f8;
  min-width: 960px;
  font-size: 16px;
}

p {
  margin: 15px 0;
}

label {
  font-weight: normal;
}

.utility-page-layout {
  max-width: 1200px;
  width: 95%;
  margin: auto;
}

.utility-page-flex-layout {
  max-width: 1200px;
  width: 95%;
  margin: auto;
  display: flex;
}

.forgot-password-div {
  border: 1px solid brown;
  display: inline;
  padding: 5px;
}

.forgot-password-flex-column {
  display: flex;
  max-width: 1200px;
  width: 95%;
  flex-flow: column;
  justify-content: left;
}

.android-support-button {
  margin-top: 35px;
}
//=========================
//= Form Utilities
//=========================

.utility-text-input,
.DayPickerInput input {
  width: 259px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
  font-size: 16px;
  padding: 8px 5px;
}

.utility-spacing-2x {
  margin: 2.4em;
}

.utility-row-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.utility-row-view input {
  margin-top: 2em;
  max-width: 4em;
  text-align: center;
}

.utility-inline-label {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;

  p {
    font-size: 14px;
    margin: 0 0 7px;
    color: #152935;
  }
}

.utility-textarea {
  border-radius: 4px;
  border: solid 1px #cccccc;
  width: 580px;
  resize: none;
  height: 90px;
  font-size: 16px;
  padding: 8px 5px;
}

.utility-flex-end {
  display: flex;
  justify-content: flex-end;
}

.utility-flex-data {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.rc-color-picker-wrap .rc-color-picker-trigger {
  width: 170px;
  height: 30px;
  border: solid 1px #cccccc;
  border-radius: 4px;
}

//=========================
//= Button Utilities
//=========================

.utility-btn-primary {
  background-color: #0089b7;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.utility-btn-transparent {
  background-color: transparent;
  color: #0076ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
}

.utility-btn-danger {
  background-color: #d0021b;
  color: #fff;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
}

//=========================
//= Table Utilities
//=========================

.utility-table-row {
  display: flex;
  align-items: center;
  border-bottom: #eceeef 1px solid;
  transition: border 0.15s;

  &:last-of-type {
    border-bottom: none;
  }

  // &:first-of-type:hover {
  //   border-bottom: #eceeef 1px solid;
  // }

  &:hover {
    border-bottom: 1px solid #0089b7;
    background: rgba(0, 0, 0, 0.03);
  }

  h2 {
    font-size: 16px;
  }

  p {
    padding: 0 5px 0 0;
  }

  .utility-table-column {
    padding: 16px 14px;
    flex: 1;
  }
  .utility-table-column-expanded{
    flex-grow: 5;
    text-align: left;
  }
} // /.utility-table-row
thead{
  tr{
    border-bottom: #d3d3d3 1px solid;

    th{
      padding: 5px;
    }
  }
}
tbody{
  tr {
    border-bottom: #eceeef 1px solid;
    transition: border 0.15s;
    &:hover {
      border-bottom: 1px solid #0089b7;
      background: rgba(0, 0, 0, 0.03);
    }
    &:last-of-type {
      border-bottom: none;
    }
    td{
      width: min-content;
      padding: 5px;
      word-break: break-word;
    }
    td.max-content {
      width: 50%;
    }
  }
}

.utility-table-row-headers{
  font-size: 16px;
}

.utility-table-end-col {
  display: flex;
}

.utility-edit-link {
  color: #0089b7;
  margin-left: auto;
  text-decoration: none;
}

.utility-active {
  color: #09c98e;
}

//=========================
//= Spacing Utilities
//=========================

.utility-lr-margin-small {
  margin-left: 10px;
  margin-right: 10px;
}

.utility-lr-margin-md {
  margin-left: 30px;
  margin-right: 30px;
}

//=========================
//= Layout Utilities
//=========================

.utility-flex-row {
  display: flex;
}

.sort-handle{
  cursor: grab;
  font-size: 20px;
  display: flex;
  text-align: center;
  align-items: center;
}
.utility-flex-row-dragging{
  rotate: 1.25deg;
}
.utility-flex-row-dragging .sort-handle{
  cursor: grabbing;
  pointer-events: all !important;
}

//========================
//=  Highlight Animations
//========================

.row-successful-highlight{
  transition: background-color 1s;
  background-color: lightgreen;
}

.row-normal-fade-out{
  transition: background-color 1s;
  background-color: transparent;
}

//========================
//=  React Pagination
//========================

/* Container */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* Individual Page Numbers, Next and Previous Buttons */
.pagination .page, .pagination .previous, .pagination .next {
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  color: #495057;
  margin: 0 2px;
  padding: 8px 12px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
}

/* Active Page Number */
.pagination .active {
  background-color: #007bff;
  color: #ffffff;
  border-color: #007bff;
}

/* On Hover Effect */
.pagination .page:hover, .pagination .previous:hover, .pagination .next:hover {
  background-color: #d1d5da;
}

/* Disabled Previous and Next Buttons */
.pagination .disabled {
  color: #adb5bd;
  cursor: not-allowed;
}

/* Break ... */
.break-me {
  padding: 8px 12px;
  cursor: default;
}

// Feedback Screen
.sessionIcon{
  color: #0089b7;
  padding: 10px;
}