.SubNav {
  height: 50px;
  border-radius: 4px;
  background-color: #eceeef;
  display: flex;
  align-items: center;
  padding: 0 37px;
  margin-top: 20px;

  a {
    text-decoration: none;
    color: #0076ff;

    &:last-child {
      color: #4a4a4a;
    }
  }

  span {
    display: inline-block;
    margin: 0 5px;
  }
}
