.SubNav {
  height: 50px;
  border-radius: 4px;
  background-color: #eceeef;
  display: flex;
  align-items: center;
  padding: 0 37px;
  margin-top: 20px;
}
.SubNav a {
  text-decoration: none;
  color: #0076ff;
}
.SubNav a:last-child {
  color: #4a4a4a;
}
.SubNav span {
  display: inline-block;
  margin: 0 5px;
}

