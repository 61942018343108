.EntryNav {
  display: flex;
  height: 50px;
  align-items: center;
  background-color: #f7f7f9;
  padding: 0 40px;
}
.EntryNav img {
  margin: 0;
  margin-right: 24px;
  height: 30px;
  mix-blend-mode: multiply;
}
.EntryNav a {
  margin-left: auto;
  color: #4a4a4a;
  text-decoration: none;
  font-size: 16px;
}

