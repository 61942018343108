.AddEditCategory {
  &-quotes {
    padding: 20px 0;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
  }
  &-img-upload {
    input {
      display: none;
    }
  }
}
