.HeaderBar {
  margin-top: 29px;
  height: 40px;
  display: flex;
  align-items: center;

  h1 {
    font-size: 20px;
    font-weight: normal;
  }

  a {
    text-decoration: none;
  }

  &-right {
    margin-left: auto;
  }
}
