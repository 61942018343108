.Login {
  background: #fff;
  max-width: 960px;
  width: 90%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 50px;
  }

  p {
    font-size: 14px;
    margin: 14px 0 7px;
  }

  button {
    width: 100%;
    max-width: 260px;
    margin-top: 32px;
    height: 38px;
  }


}
