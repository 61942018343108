.HeaderBar {
  margin-top: 29px;
  height: 40px;
  display: flex;
  align-items: center;
}
.HeaderBar h1 {
  font-size: 20px;
  font-weight: normal;
}
.HeaderBar a {
  text-decoration: none;
}
.HeaderBar-right {
  margin-left: auto;
}

